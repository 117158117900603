import React from 'react';
import { GetStaticProps } from 'next';

import { fetchArticles } from 'lib/contentful/hub';
import { fetchHomePage } from 'lib/contentful/landingPages';
import { fetchTestimonials } from 'lib/contentful/testimonials';
import { fetchRatings } from 'shared/services/trustedShops/fetchRatings';
import Homepage from 'static_pages/home';
import { HomepageProps } from 'static_pages/home/model';

function Home(props: HomepageProps) {
  return <Homepage {...props} />;
}

export const getStaticProps: GetStaticProps<HomepageProps> = async ({
  locale,
}) => {
  const [homePage, articles, ratings, testimonials] = await Promise.all([
    fetchHomePage({ locale }),
    fetchArticles({ locale, order: '-sys.createdAt', limit: 3 }),
    fetchRatings(),
    fetchTestimonials({ locale }),
  ]);

  const articlesWithoutCircularReferences = articles.map((article) => {
    delete article.fields.suggestedArticles;
    return article;
  });

  if (!homePage) {
    return {
      notFound: true,
    };
  }

  const props: HomepageProps = {
    ...homePage,
    testimonials,
    ratings,
    articles: articlesWithoutCircularReferences,
    hasEnglishVersion: true,
  };
  return {
    props,
  };
};

export default Home;
