import React, { useRef } from 'react';
import { Box, Typography } from '@heycater/design-system';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import EmbeddedFunnel from 'static_pages/home/variationB/components/EmbeddedFunnel';
import Steps from 'static_pages/home/variationB/components/Steps';
import Container from 'system/components/Container';

function Hero({ ratings, ...boxProps }) {
  const { t } = useTranslation('static_pages');

  const questionRef = useRef(null);

  return (
    <React.Fragment>
      <Wrapper
        as="section"
        width="100%"
        id="heroSection"
        className="hey15__control"
        {...boxProps}
      >
        <Content>
          <Container size="md">
            <Box
              mx="auto"
              px={0}
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              maxWidth={{ sm: 600, md: 1050 }}
            >
              <Title variant="h2" mb={2} component="h1">
                <Trans
                  t={t}
                  i18nKey="homepage.variationA.qf.title"
                  components={[
                    <StyledTypography
                      variant="inherit"
                      component="inherit"
                      key="title"
                    />,
                  ]}
                />
              </Title>
            </Box>
          </Container>
          <EmbeddedFunnel
            renderTitle={(title) => (
              <Subtitle component="h2" variant="subtitle2">
                {title}&nbsp;
              </Subtitle>
            )}
            ratings={ratings}
            slides={{
              category: { whiteWrapper: false },
              dateAndLead: { whiteWrapper: false },
              dietaryRestriction: { whiteWrapper: false },
              customerTier: { whiteWrapper: false },
            }}
            highlightColor="secondary"
            onBackground="light"
            ref={questionRef}
          />
          <Steps />
        </Content>
      </Wrapper>
    </React.Fragment>
  );
}

const StyledTypography = styled(Typography)`
  color: #7d8f5c;
`;

const Wrapper = styled.div`
  background: radial-gradient(
    124.78% 80.11% at 93.04% 116.94%,
    #e2ec97 7.76%,
    #e7f4c3 79.45%
  );
  position: relative;
  overflow: hidden;
  z-index: 2;
  ${({ theme }) => theme.breakpoints.up('md')} {
    overflow: visible;
  }
`;

const Title = styled(Typography)`
  font-weight: 700;
  font-size: ${({ mobile }) => (mobile ? 20 : 28)}px;
  line-height: 130%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 38px;
    line-height: 140%;
  }
`;

const Content = styled.div`
  padding: ${(props) => props.theme.spacing(4, 0, 0)};
  position: relative;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(6, 0)};
  }
`;

const Subtitle = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  white-space: pre-line;
  line-height: 150%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 18px;
  }
`;

export default Hero;
